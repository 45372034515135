<template>
  <div id="agenda-list-page">
    <section class="for-rt">
      <div class="container">
        <div class="room-title">Agenda</div>
      </div>
    </section>

    <section class="show-item">
      <div class="container">
        <form @submit.prevent="search">
          <div class="filter-row">
            <div class="ft-filter">
              <!-- Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list -->
              Showing All Results
            </div>
            <div class="form-search">
              <input type="text" class="form-control" placeholder="Search..." v-model="filter">
              <button type="submit" class="btn btn-search"><span class="icon-ico-search"></span></button>
            </div>
          </div>
        </form>
      </div>
    </section>

    <section class="all-agenda">
      <div class="agenda-inside">
        <div class="container">
          <div class="row">
            <template v-for="(agenda, index) in agendas">
              <div class="col-lg-4 col-sm-6" :key="index">
                <div @click="detailAgenda(agenda.slug)" class="box-white mb30">
                  <div class="inner-agenda">
                    <div class="date-agenda">
                      {{ agenda.day }}
                      <span>{{ agenda.date }}</span>
                    </div>
                    <div class="agenda-text">
                      <h6>{{ agenda.title }}</h6>
                      {{ agenda.description | short_description(125) }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- <div class="container">
        <div class="d-flex justify-content-center mt-5">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
          </ul>
        </div>
      </div> -->
    </section>

    <!-- popup detail agenda -->
    <div class="popup-agenda" :class="{ show: Object.keys(agenda).length > 0 }">
      <div class="popup-agenda-inner">
        <div class="agenda-close">
          <button class="btn btn-close for-agenda" @click="agenda = {}"></button>
        </div>
        <div class="mb24">
          <table class="table borderless" cellspacing="2">
            <tr>
              <td class="point">Title</td>
              <td>:</td>
              <td class="point-desc">{{ agenda.title }}</td>
            </tr>
            <tr>
              <td class="point">Start Date</td>
              <td>:</td>
              <td class="point-desc">{{ agenda.start_date }}</td>
            </tr>
            <tr>
              <td class="point">End Date</td>
              <td>:</td>
              <td class="point-desc">{{ agenda.end_date }}</td>
            </tr>
            <tr>
              <td class="point">Description</td>
              <td>:</td>
              <td class="point-desc" v-html="agenda.description"></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgendaList",
  data() {
    return {
      totalRows: 0,
      currentPage: 1,
      perPage: 9,
      filter: null,
      sortBy: "agendas.created_at",
      sortDesc: false,
      agendas: [],
      agenda: {}
    }
  },
  watch: {
    currentPage(){
      this.fetchAgenda()
    }
  },
  computed: {
    startRow() {
      if (this.currentPage == 1) {
        return 1
      } else {
        return (this.perPage * (this.currentPage - 1 )) + 1
      }
    },
    untilRow() {
      if ((this.currentPage * this.perPage) <= this.totalRows) {
        return this.currentPage * this.perPage
      } else {
        return this.totalRows
      }
    }
  },
  mounted() {
    this.fetchAgenda()
  },
  methods: {
    fetchAgenda() {
      this.$axios.get(`/master/agenda/view_all`, {
        params: {
          page: this.currentPage,
          per_page: this.perPage,
          search: this.filter,
          sortBy: this.sortBy,
          sortType: (this.sortDesc)? 'asc' : 'desc'
        }
      }).then(response => {
        this.agendas = response.data.data
        //this.totalRows = response.data.data.meta.total
      })
    },
    search() {
      this.page = 1
      this.fetchAgenda()
    },
    detailAgenda(slug) {
      this.$axios.get(`/master/agenda/${slug}`)
        .then(response => {
          this.agenda = response.data.data
        })
    },
  }
}
</script>

<style scoped>
.box-white:hover {
  cursor: pointer;
}
.inner-agenda {
  height: 109px !important;
}
td {
  padding: 10px;
}
.point {
  width: 20%;
  font-size: 14px;
  color: #7B7B7B;
}
.point-desc {
  width: 80%;
  font-weight: 600;
  font-size: 14px;
  color: #434343;
}
</style>